import React from 'react';
import RelatedCard from './RelatedCard';

export default function RelatedInvestigatorCard(props) {
  const { investigator } = props;

  return (
    <RelatedCard
      title={investigator.name}
      link={`/investigator/${investigator.slug}`}
      imageHandle={investigator.image.handle}
      imageTitle={investigator.image.title}
    />
  );
}