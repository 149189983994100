import React from 'react';
import { makeStyles } from '@material-ui/core/styles';



const useStyles = makeStyles((theme) => ({
  videoWrapper: {
    position: 'relative',
    paddingBottom: '56.25%', /* 16:9 */
    height: 0,

    '& iframe': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    }  
  }
}));


export default function VideoEmbed (props) {
  const classes = useStyles();
  const { iframe } = props;

  return (
    <div 
      className={classes.videoWrapper}
      dangerouslySetInnerHTML={{__html: iframe}}
    />
  );
}