import React from 'react';
// import gql from 'graphql-tag';
// import { graphql } from 'react-apollo';
import Markdown from 'react-markdown';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Icon from '@material-ui/core/Icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import VideoEmbed from '../components/VideoEmbed';

import RelatedPlaceCard from '../components/RelatedPlaceCard';
import RelatedPersonCard from '../components/RelatedPersonCard';
import RelatedLoreCard from '../components/RelatedLoreCard';
import RelatedInvestigatorCard from '../components/RelatedInvestigatorCard';



import Layout from '../components/Layout';
import SEO, {SEODescription, SEOImage} from '../components/SEO';

import { graphql } from 'gatsby';

import MuiOutboundLink from '../components/MuiOutboundLink';


const useStyles = makeStyles((theme) => ({
  articleContainer: {
    padding: theme.spacing(2),
  },
  section: {
    paddingTop: theme.spacing(2),
  },
  amazonLink: {
    // display: 'flex',
    // alignItems: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  }
}));

const Investigation = ({ data }) => {
  const classes = useStyles();
  const investigation = data.gcms.investigation;

  return (
    <Layout>

      <SEO 
        title={investigation.title}
        description={SEODescription(investigation.description.text)}
        image={SEOImage(investigation.image.handle)}
        article={true}
      />

      <Paper className={classes.articleContainer}>
        <article>
          <Typography variant="h3" gutterBottom={true}>
            {investigation.title}
          </Typography>
          <img
            src={`https://media.graphcms.com/resize=w:1200/${investigation.image.handle}`}
            title={investigation.image.title}
            style={{ width: '100%' }}
          />
          <Markdown source={investigation.description.markdown} escapeHtml={false} />

          {(investigation.linkAmazon || investigation.video) &&
            <React.Fragment>
              <Typography variant="h4">
                Watch
                </Typography>

              {investigation.linkAmazon &&
                <Button
                  component={MuiOutboundLink}
                  href={investigation.linkAmazon}
                  target="_blank" rel="noopener"
                  className={classes.amazonLink}
                  variant="contained"
                  color="secondary"
                  startIcon={<FontAwesomeIcon icon={["fab", "amazon"]} />}
                >
                  Amazon Prime Video
                  </Button>
              }

              {investigation.video &&
                <VideoEmbed iframe={investigation.video} />
              }
            </React.Fragment>
          }

          <Divider variant="middle" />

          {investigation.investigator &&
            <section className={classes.section}>
              <Typography variant="h5" gutterBottom={true}>
                Investigator
                </Typography>
              <Grid container spacing={1}>
                <RelatedInvestigatorCard
                  investigator={investigation.investigator}
                />
              </Grid>
            </section>
          }

          {investigation.places.length > 0 &&
            <section className={classes.section}>
              <Typography variant="h5" gutterBottom={true}>
                Places invstigated
                </Typography>
              <Grid container spacing={1}>
                {investigation.places.map(place => (
                  <RelatedPlaceCard
                    key={place.id}
                    place={place}
                  />
                ))}
              </Grid>
            </section>
          }

          {investigation.people.length > 0 &&
            <section className={classes.section}>
              <Typography variant="h5" gutterBottom={true}>
                People investigated
                </Typography>
              <Grid container spacing={1}>
                {investigation.people.map(person => (
                  <RelatedPersonCard
                    key={person.id}
                    person={person}
                  />
                ))}
              </Grid>
            </section>
          }

          {investigation.lores.length > 0 &&
            <section className={classes.section}>
              <Typography variant="h5" gutterBottom={true}>
                Stories investigated
                </Typography>
              <Grid container spacing={1}>
                {investigation.lores.map(lore => (
                  <RelatedLoreCard
                    key={lore.id}
                    lore={lore}
                  />
                ))}
              </Grid>
            </section>
          }

        </article>
      </Paper>
    </Layout>
  );
};

export const query = graphql`
  query singleInvestigation($slug: String!) {
    gcms {
      investigation(where: {slug: $slug}) {
        id
        title
        image {
          handle
          title
        }
        video
        description {
          markdown
          text
        }
        linkAmazon
        investigator {
          id
          name
          slug
          image {
            handle
            title
          }
        }
        lores {
          id
          title
          slug
          gallery(first: 1) {
            handle
            title
          }
        }
        places {
          id
          name
          slug
          gallery(first: 1) {
            handle
            title
          }
        }
        people {
          id
          name
          slug
          gallery(first: 1) {
            handle
            title
          }
        }
      }
    }
  }
`;

export default Investigation;







// const Investigation = ({ data: { loading, error, investigation } }) => {
//   const classes = useStyles();

//   if (error) return <h1>Error fetching the investigation!</h1>;
//   if (!loading) {
//     return (
//       <Paper className={classes.articleContainer}>
//         <article>
//           <Typography variant="h3" gutterBottom={true}>
//             {investigation.title}
//           </Typography>
//           <img 
//             src={`https://media.graphcms.com/resize=w:1200/${investigation.image.handle}`} 
//             title={investigation.image.title} 
//             style={{width: '100%'}}
//           />
//           <Markdown source={investigation.description.markdown} escapeHtml={false} />

//           { (investigation.linkAmazon || investigation.video) && 
//             <React.Fragment>
//               <Typography variant="h4">
//                 Watch
//               </Typography>

//               {investigation.linkAmazon && 
//                 <Button 
//                   href={investigation.linkAmazon} 
//                   target="_blank" rel="noopener"
//                   className={classes.amazonLink} 
//                   variant="outlined"
//                   color="primary"
//                   startIcon={<Icon className="fab fa-amazon"/>}
//                 >
//                   Amazon Prime Video
//                 </Button>
//               }

//               {investigation.video &&
//                 <VideoEmbed iframe={investigation.video} />
//               }
//             </React.Fragment>
//           }

//           <Divider variant="middle" />

//           {investigation.investigator &&
//             <section className={classes.section}>
//               <Typography variant="h5" gutterBottom={true}>
//                 Investigator
//               </Typography>
//               <Grid container spacing={1}>
//                   <RelatedCard
//                     title={investigation.investigator.name}
//                     link={`/investigator/${investigation.investigator.slug}`}
//                     imageHandle={investigation.investigator.image.handle}
//                     imageTitle={investigation.investigator.image.title}
//                   />
//               </Grid>
//             </section>
//           }

//           {investigation.places.length > 0 &&
//             <section className={classes.section}>
//               <Typography variant="h5" gutterBottom={true}>
//                 Places invstigated
//               </Typography>
//               <Grid container spacing={1}>
//                 {investigation.places.map(place => (
//                   <RelatedCard
//                     title={place.name}
//                     link={`/place/${place.slug}`}
//                     imageHandle={place.gallery[0].handle}
//                     imageTitle={place.gallery[0].title}
//                   />
//                 ))}
//               </Grid>
//             </section>
//           }

//           {investigation.people.length > 0 &&
//             <section className={classes.section}>
//               <Typography variant="h5" gutterBottom={true}>
//                 People investigated
//               </Typography>
//               <Grid container spacing={1}>
//                 {investigation.people.map(person => (
//                   <RelatedCard
//                     title={person.name}
//                     link={`/person/${person.slug}`}
//                     imageHandle={person.gallery[0].handle}
//                     imageTitle={person.gallery[0].title}
//                   />
//                 ))}
//               </Grid>
//             </section>
//           }

//           {investigation.lores.length > 0 &&
//             <section className={classes.section}>
//               <Typography variant="h5" gutterBottom={true}>
//                 Stories investigated
//               </Typography>
//               <Grid container spacing={1}>
//                 {investigation.lores.map(lore => (
//                   <RelatedCard
//                     title={lore.title}
//                     link={`/lore/${lore.slug}`}
//                     imageHandle={lore.gallery[0].handle}
//                     imageTitle={lore.gallery[0].title}
//                   />
//                 ))}
//               </Grid>
//             </section>
//           }

//         </article>
//       </Paper>
//     );
//   }
//   return <h2>Loading investigation...</h2>;
// };

// export const singleInvestigation = gql`
//   query singleInvestigation($slug: String!) {
//     investigation(where: {slug: $slug}) {
//       id
//       title
//       image {
//         handle
//         title
//       }
//       video
//       description {
//         markdown
//       }
//       linkAmazon
//       investigator {
//         name
//         slug
//         image {
//           handle
//           title
//         }
//       }
//       lores {
//         title
//         slug
//         gallery(first: 1) {
//           handle
//           title
//         }
//       }
//       places {
//         name
//         slug
//         gallery(first: 1) {
//           handle
//           title
//         }
//       }
//       people {
//         name
//         slug
//         gallery(first: 1) {
//           handle
//           title
//         }
//       }
//     }
//   }
// `;

// export default graphql(singleInvestigation, {
//   options: ({ match }) => ({
//     variables: {
//       slug: match.params.slug,
//     },
//   }),
// })(Investigation);